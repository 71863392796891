.notificationCard div:global(.card-block) {
  padding: 0px;
}

.timestamp {
  padding-left: 16px;
  margin-top: 24px;
  margin-bottom: 8px;
}

.notification {
  border-bottom: 1px solid var(--alloy);
  padding: 16px;
  display: flex;
  flex-direction: row;
}

.notification > svg {
  margin-right: 10px;
}

.notification > a {
  margin-left: auto;
}

.active {
  background-color: var(--button-hover);
}

.linkButton {
  color: var(--link);
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
}

.linkButton:hover,
.linkButton:focus {
  text-decoration: none;
}

.withButtonOnRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.withButtonOnRight button {
  margin: 10px;
}
