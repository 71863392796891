.uploadView {
  border: 1px solid var(--platinum);
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
}

.uploadView + .uploadView {
  border-top: 0px;
}

.uploadView:last-child {
  margin-bottom: 16px;
}

.uploadView > div:first-child {
  font-weight: 600;
}

.uploadView > div:last-child {
  margin-left: auto;
}

.uploadViewHeader {
  padding-top: 8px;
}
