.objectSpec {
  padding-bottom: 8px;
}

.viewSpec {
  border: 1px solid var(--platinum);
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
}

.viewSpec + .viewSpec {
  border-top: 0px;
}

.viewSpec:last-child {
  margin-bottom: 16px;
}

.viewSpec > div:first-child {
  font-weight: 600;
}

.viewSpec > div:last-child {
  margin-left: auto;
}

.viewSpec:nth-child(even) {
  background-color: var(--horizon);
}
