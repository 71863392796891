div .incompleteCarrierList {
  margin-top: 20px;
  background-color: var(--platinum);
  border: 0;
}

.incompleteCarrierList table:global(.table) {
  border: 1px solid var(--alloy);
}

.incompleteCarrierList table:global(.table) td {
  border-top: 1px solid var(--alloy);
}

.incompleteCarrierList :global(.ij-icon) {
  position: relative;
  margin-right: 3px;
}

.completeCarrierList {
  margin-top: 20px;
}

.incompleteCarrierList table,
.completeCarrierList table {
  margin-bottom: 0px;
}

td.carrierLogo {
  width: 1%;
}

td.carrierLogo > div {
  width: 60px;
}

:global(.table) tr td.carrierLogo {
  width: 10%;
}

td.carrierLogo > div {
  border-radius: 50%;
  border: 2px solid var(--alloy);
  width: 60px;
  height: 60px;
}

:global(.table) tr td.carrierName {
  vertical-align: middle;
  width: 25%;
  white-space: nowrap;
}

:global(.table) tr td.carrierServiceCount {
  vertical-align: middle;
  max-width: 100%;
  white-space: nowrap;
}

.incompleteCarrierList .deleteButtonContainer {
  vertical-align: middle;
  text-align: right;
}

.incompleteCarrierList .deleteButton svg {
  fill: var(--shale);
}

.incompleteCarrierList .deleteButton:hover svg {
  fill: var(--ocean-base);
}
