.pickup-schedule-editor .btn.remove {
  width: 40px;
  outline: none;
  padding: 10px;
}

.pickup-schedule-editor .pickup-editor-time > input {
  text-align: center;
}

.pickup-schedule-editor .pickup-editor-row {
  display: flex;
  margin-bottom: 16px;
  white-space: nowrap;
}
