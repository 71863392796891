.location-groupby-dropdown
  .form-group
  .Select--single
  > .Select-control
  .Select-value {
  padding-top: 16px;
}

.location-groupby-dropdown .form-group-active label {
  display: none;
}

.location-groupby-dropdown .form-group.form-group-active input {
  padding-top: 16px;
}

.select-location-row {
  display: flex;
  flex-direction: row;
}
