.carrier-explorer-services {
  overflow: auto;
  height: auto;
  padding: 0.5rem;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  display: flex;
  flex-flow: column;
}

.carrier-explorer-container {
  display: flex;
  overflow: hidden;
  height: calc(100vh - 96px);
  position: relative;
  width: 100%;
  backface-visibility: hidden;
  will-change: overflow;
}

#root .carrier-explorer-app {
  margin-top: -34px;
  padding: 0px 15px 0px 0px;
  margin-left: auto;
  margin-right: auto;
}

.carrier-explorer-container.card {
  border-radius: 0px;
  border-left: 0px;
}

.capabilities .tag {
  margin-bottom: 8px;
  margin-left: 8px;
}

.capabilities-select > div {
  border: none;
}
