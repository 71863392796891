.preloaderWrapper {
  position: absolute;
  z-index: 10;
  top: 0px;
  left: 0;
  width: 100%;
  margin-top: 10px;
}

.loadingWrapper {
  position: relative;
}

.loadingWrapper .form-group.form-group-active input {
  padding-bottom: 0px;
}
