.configuration:global(.card-block) {
  padding: 0px;
}

.configuration:global(.card-block) :global(.list-group) {
  box-shadow: none;
  margin-bottom: 0px;
}

.configuration:global(.card-block) :global(.list-group-item) {
  border: none;
  border-bottom: 2px solid var(--alloy);
}
.configuration:global(.card-block) :global(.list-group-item):last-child {
  border: none;
}

.locationsCard {
  margin-bottom: 30px;
}

.locationsCard :global(.card-block) {
  padding: 0;
}

.locationsCard :global(.card-block) :global(.ReactTable) > :global(.rt-table) {
  margin: 0;
}

.marginBottomFull {
  margin-bottom: 24px;
}

.marginTop {
  margin-top: 20px;
}
