:root {
  --parsimon-base: #f0553a;
  --parsimon-darker: #d63625;
  --parsimon-darkest: #bc2111;
  --cobalt-base: #3a414c;
  --cobalt-darker: #212730;
  --cobalt-darkest: #0d1116;
  --ocean-base: #0088a9;
  --ocean-darker: #00738e;
  --ocean-darkest: #005c75;
  --teal-base: #006f8a;
  --teal-darker: #005b70;
  --teal-darkest: #004456;
  --sky-base: #a9d6e5;
  --sky-darker: #87b9c9;
  --sky-darkest: #689baf;
  --granite-base: #4f5d75;
  --granite-darker: #354259;
  --granite-darkest: #212c3f;
  --coal: #333943;
  --shale: #697582;
  --slate: #8090a2;
  --alloy: #c4cdd6;
  --platinum: #e1e6eb;
  --silver: #eff3f5;
  --horizon: #edf3f9;
  --white: #ffffff;
  --success-base: #35ad96;
  --success-darker: #22917e;
  --success-darkest: #127769;
  --info-base: #41b5dc;
  --info-darker: #2b9dc1;
  --info-darkest: #188aa8;
  --warning-base: #ffa23f;
  --warning-darker: #e58628;
  --warning-darkest: #cc7712;
  --warning-pale: #ffdbb5;
  --danger-base: #d24a35;
  --danger-darker: #b73721;
  --danger-darkest: #9e2810;
  --link: #0088a9;
  --link-visited: #00738e;
  --link-hover: #0088a9;
  --working-day: #3295b5;
  --button-hover: #cee8f0;
}
