.configuration:global(.card-block) {
  padding: 0px;
}

.configuration:global(.card-block) :global(.list-group) {
  box-shadow: none;
  margin-bottom: 0px;
}

.configuration:global(.card-block) :global(.list-group-item) {
  border: none;
  border-bottom: 2px solid var(--alloy);
}
.configuration:global(.card-block) :global(.list-group-item):last-child {
  border: none;
}
