.dowSelector :global(.btn-group) :global(.btn.enabled).dayButton {
  background: var(--sky-base);
}

.dowSelector :global(.btn-group) .dayButton,
.dowSelector :global(.btn-group) .dayButton:active,
.dowSelector :global(.btn-group) .dayButton:focus {
  color: var(--link);
  background: var(--white);
  border: 1px solid var(--ocean-base);
  width: 40px;
  height: 50px;
  padding: 10px;
  outline: none;
}
