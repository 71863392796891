.sequence {
  border: 1px solid var(--platinum);
  border-right: none;
  display: flex;
}

.sequence + .sequence {
  border-top: 0px;
}

.sequence .sequenceView {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  padding: 8px;
  border-right: 1px solid var(--platinum);
}

.sequence:last-child {
  margin-bottom: 16px;
}

.sequenceView > div:first-child {
  font-weight: 600;
}

.sequenceView > div:last-child {
  margin-left: auto;
}

.subHeader {
  padding-top: 8px;
  padding-bottom: 4px;
}
