.region-table:global(.table) > tbody > tr:nth-of-type(even) {
  background-color: var(--silver);
}

.region-table:global(.table) > tbody > tr:nth-of-type(odd) {
  background-color: var(--white);
}
.region-table:global(.table) th {
  font-weight: 600;
}
