.configuration:global(.card-block) {
  padding: 0px;
}

.configuration:global(.card-block) :global(.list-group) {
  box-shadow: none;
  margin-bottom: 0px;
}

.configuration:global(.card-block) :global(.list-group-item) {
  border: none;
  border-bottom: 2px solid var(--alloy);
}
.configuration:global(.card-block) :global(.list-group-item):last-child {
  border: none;
}

.card {
  margin-top: 20px;
}

td.carrierLogo {
  width: 1%;
}

td.carrierLogo > div {
  width: 60px;
}

td.carrierLogo > div {
  border-radius: 50%;
  border: 2px solid var(--alloy);
  width: 60px;
  height: 60px;
}

td.carrierName {
  padding-left: 7px;
  vertical-align: middle;
  width: 25%;
  white-space: nowrap;
}
