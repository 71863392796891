.marginTop {
  margin-top: 20px;
}

.marginBottom {
  margin-bottom: 32px;
}
.advancedAuditTable :global(.pagination-bottom) {
  margin: 0 8px;
}
