.userTable > tbody > .userRow > .userProfile {
  padding-left: 11px;
}
.userAvatar img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 11px;
}

.userAvatar.pendingUser {
  width: 47px;
}

.userTable > tbody > .userRow > td {
  vertical-align: middle;
}

.roleCheckbox:global(.checkbox) {
  border: 1px solid var(--platinum);
  padding: 8px;
  margin-bottom: 4px;
}

.roleCheckbox:hover {
  background-color: var(--horizon);
}

.roleCheckbox div {
  margin-left: 24px;
}

.clickable:hover {
  cursor: pointer;
}

.userRow .deleteButton svg {
  fill: var(--shale);
}

.userRow .deleteButton:hover svg {
  fill: var(--ocean-base);
}

.user-editor-drawer :global(.Select-input) {
  width: 100%;
}
