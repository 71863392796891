.withRightContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
}

.withRightContent .rightContent {
  margin-left: auto;
}

.carrierServicesTable:global(.table) tr > td:first-child {
  padding-left: 8px;
}

.carrierServicesTable:global(.table) {
  margin-bottom: 0px;
}
